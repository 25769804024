import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';
import { useCookies } from 'react-cookie';
import css from './ListingPage.module.css';

import {
  getDefaultColor,
  getDefaultCondition,
  getDefaultMaterial,
  getmarketplaceData,
} from '../../marketplace-data';
const SectionPublicDataMaybe = ({ publicData }) => {
  if (!publicData) {
    return null;
  }
  const {
    subCategory: product,
    size,
    designer: brand,
    department: category,
    category: subCategory,
    gender,
    //from features
    colour: color,
    condition,
    material,
    bagHeight,
    bagWidth,
    bagDepth,
    walletHeight,
    walletWidth,
    otherBrand,
    refrenceNumber,
  } = publicData || {};
  const [cookies] = useCookies(['language']);
  const marketplaceData = getmarketplaceData(cookies?.language);
  const colorOptions = getDefaultColor(cookies?.language);
  const conditionOptions = getDefaultCondition(cookies?.language);
  const materialOptions = getDefaultMaterial(cookies?.language);
  const data = marketplaceData;
  const categoryData = data?.find(d => d.value === category);
  const genderData = categoryData?.gender;
  const subCategoryData = gender
    ? genderData?.enumOptions?.find(d => d.value === gender)?.subCategories?.enumOptions
    : categoryData?.subCategories?.enumOptions;
  const subCategoryFinal = subCategory ? subCategoryData?.find(d => d.value === subCategory) : null;
  const productData = subCategoryData?.find(d => d.value === subCategory)?.products?.enumOptions;
  const productObject = product ? productData?.find(d => d.value === product) : null;
  const brandObject = brand
    ? productObject?.brands?.enumOptions?.find(d => d.value === brand)
    : null;
  const sizeObject = size ? productObject?.sizes?.enumOptions?.find(d => d.value === size) : null;
  const genderFinal = gender ? genderData?.enumOptions?.find(d => d.value === gender) : null;

  const colorLabel = color ? colorOptions?.find(option => option.value === color)?.label : null;
  const conditionLabel = condition
    ? conditionOptions?.find(option => option.value === condition)?.label
    : null;
  const materialLabel = material
    ? materialOptions?.find(option => option.value === material)?.label
    : null;
  return (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {categoryData && category ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.category" />
            </span>
            <span>{categoryData.label}</span>
          </li>
        ) : null}
        {gender && genderFinal ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.gender" />
            </span>
            <span>{genderFinal.label}</span>
          </li>
        ) : null}
        {subCategory && subCategoryFinal ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.subCategory" />
            </span>
            <span>{subCategoryFinal.label}</span>
          </li>
        ) : null}
        {product && productObject ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.product" />
            </span>
            <span>{productObject.label}</span>
          </li>
        ) : null}
        {brand && brandObject ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.brand" />
            </span>
            <span>{otherBrand || brandObject.label}</span>
          </li>
        ) : null}
        {size && sizeObject ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.size" />
            </span>
            <span>{sizeObject.label}</span>
          </li>
        ) : null}
        {color && colorLabel ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.colorTitle" />
            </span>
            <span>{colorLabel}</span>
          </li>
        ) : null}
        {condition && conditionLabel ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.conditionTitle" />
            </span>
            <span>{conditionLabel}</span>
          </li>
        ) : null}
        {material && materialLabel ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.materialTitle" />
            </span>
            <span>{materialLabel}</span>
          </li>
        ) : null}
        {bagHeight ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.bagHeightTitle" />
            </span>
            <span>{bagHeight}</span>
          </li>
        ) : null}
        {bagWidth ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.bagWidthTitle" />
            </span>
            <span>{bagWidth}</span>
          </li>
        ) : null}
        {bagDepth ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.bagDepthTitle" />
            </span>
            <span>{bagDepth}</span>
          </li>
        ) : null}
        {walletHeight ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.walletHeightTitle" />
            </span>
            <span>{walletHeight}</span>
          </li>
        ) : null}
        {walletWidth ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.walletWidthTitle" />
            </span>
            <span>{walletWidth}</span>
          </li>
        ) : null}
        {refrenceNumber ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.SectionPublicDataMaybe.referenceNumberLabel" />
            </span>
            <span>{refrenceNumber}</span>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default SectionPublicDataMaybe;
